import { Chain } from 'viem';
import { chains } from '@/config/chains';
import { proxy } from 'valtio';

const defaultChain = () => {
    // It doesn't work on linea sepolia
    // const chainByDevMode = process.env.NEXT_PUBLIC_DEV_MODE ? 59141 : 137;
    const chainByDevMode = 137;

    const chainId =
        typeof localStorage !== 'undefined' && Number(localStorage.getItem('chainId'))
            ? Number(localStorage.getItem('chainId'))
            : chainByDevMode;

    const chain = chains.find((chain) => chain.id === chainId);

    if (!chain) {
        return chains.find((chain) => chain.id === 137);
    }

    return chain;
};

// TODO: move all fee-related types and constants to a separate file
export const DEFAULT_FEE_TYPE = 'native' as const;

// string here represents sponsorshipPolicyId for the sponsor fee type
export type TSmartAccountClientMode = 'native' | 'with_paymaster' | string;

export interface TTransactionConfigs {
    withdrawal: TSmartAccountClientMode;
    swap: TSmartAccountClientMode;
    lock: TSmartAccountClientMode;
    unlock: TSmartAccountClientMode;
    daily_claim: TSmartAccountClientMode;
    daily_claim_tap_app: TSmartAccountClientMode;
    mint: TSmartAccountClientMode;
    base: TSmartAccountClientMode;
}

/**
 * Types
 */
interface State {
    testnets: boolean;
    activeChain: Chain | undefined;
    prevChainId: number;
    currentBlock: number;
    totalValueLocked: string;
    totalValueLockedLoading: boolean;
    litevaultAddress?: string;
    paymasterAddresses?: { [tokenAddress: string]: string };
    transactionConfigs?: TTransactionConfigs;
    appReady: boolean;
}

/**
 * State
 */
const state = proxy<State>({
    testnets: typeof localStorage !== 'undefined' ? Boolean(localStorage.getItem('testnets')) : true,
    activeChain: defaultChain(),
    prevChainId: defaultChain()?.id ?? 0,
    currentBlock: 0,
    totalValueLocked: '0',
    appReady: false,
    totalValueLockedLoading: false,
});

/**
 * Store / Actions
 */
const SettingsStore = {
    state,

    setAppReady() {
        state.appReady = true;
    },

    setActiveChain(value: Chain | undefined) {
        state.activeChain = value;
        localStorage.setItem('chainId', value ? value.id.toString() : '');
    },

    setLiteVaultContractAddress(value: string) {
        state.litevaultAddress = value;
    },

    setPaymasterAddresses(value: { [tokenAddress: string]: string }) {
        state.paymasterAddresses = value;
    },

    setTransactionConfigs(value: TTransactionConfigs) {
        state.transactionConfigs = value;
    },

    setPrevChainId(value: number | undefined) {
        state.prevChainId = value ?? 0;
    },

    setCurrentBlock(value: number) {
        state.currentBlock = value;
    },

    setTVL(payload: string) {
        state.totalValueLocked = payload;
        state.totalValueLockedLoading = false;
    },

    setTVLLoading(payload: boolean) {
        state.totalValueLockedLoading = payload;
    },

    toggleTestNets() {
        state.testnets = !state.testnets;
        if (state.testnets) {
            localStorage.setItem('testnets', 'true');
        } else {
            localStorage.removeItem('testnets');
        }
    },
};

export default SettingsStore;
