import {
    base,
    bsc,
    gnosis,
    linea,
    lineaSepolia,
    mainnet,
    optimism,
    polygon,
    polygonMumbai,
    scroll,
    arbitrum,
    sepolia,
} from 'viem/chains';

const supportedChains = (
    process.env.NEXT_PUBLIC_SUPPORTED_CHAINS?.split(',').map(chain => {
        const sanitizedChain = chain.trim().replace(/^\"|\"$/g, '');
        const number = Number(sanitizedChain);

        return !isNaN(number) && number !== 0 ? number : null;
    }) ?? [137, 80002]
).filter(chain => chain !== null);

const chainsArray = [
    mainnet,
    sepolia,
    linea,
    lineaSepolia,
    polygon,
    polygonMumbai,
    optimism,
    gnosis,
    scroll,
    base,
    bsc,
    arbitrum,
];

export const chains = chainsArray.filter(chain => supportedChains.includes(chain.id));
