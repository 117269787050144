'use client';

import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';

import { Spinner } from '@/assets/images/Spinner';
import { YellowLogo } from '@/assets/images/VaultLogo';
import { useSetMobileDevice } from '@/hooks/Vault/useMobileDevice';
import { usePriorityNavigation } from '@/hooks/usePriorityNavigation';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { RenderAccountWithVault } from './helpers/RenderAccountWithVault';
import Link from 'next/link';

interface NavItem {
    name: string | ReactNode;
    href: string;
}

export interface HeaderOptions {
    navigations?: NavItem[];
    customHeaderContent?: React.ReactNode;
    responseIsMobile?: boolean;
}

export interface IHeaderProps {
    options?: HeaderOptions;
    headerLogoIcon?: JSX.Element;
    userEmail?: string;
    accountAddress?: string;
    loading?: boolean;
    connect?: () => Promise<void>;
    onLogOut?: () => void;
    setOpenConnect?: (open: boolean) => void;
    hideOptionsMenu?: boolean;
    show?: boolean;
    isVaultLayout?: boolean;
    actionButtonLabel?: string;
}

const Header: FC<IHeaderProps> = ({
    options,
    loading,
    connect,
    onLogOut,
    isVaultLayout,
    headerLogoIcon,
    accountAddress,
    setOpenConnect,
    hideOptionsMenu,
    actionButtonLabel,
}: IHeaderProps) => {
    const pathname = usePathname();
    const isMobile = useSetMobileDevice(false, 1025);

    const navigationContainer = useRef<HTMLDivElement>(null);
    const navigation = useRef<HTMLElement>(null);
    const moreMenu = useRef<HTMLDivElement>(null);

    const mobileDevice = useMemo(
        () => (isMobile === undefined ? options?.responseIsMobile : !!isMobile),
        [isMobile, options?.responseIsMobile],
    );

    const [priorityNavigation, moreNavigation] = usePriorityNavigation({
        navigationItems: options?.navigations || [],
        navigationContainer: navigationContainer.current,
        navigation: navigation.current,
        moreMenu: moreMenu.current,
        minimumNumberInNav: 2,
        triggerRerender: accountAddress || !onLogOut,
    });

    const logoClickHandler = useCallback(() => {
        pathname === '/' ? window?.scrollTo({ top: 0, behavior: 'smooth' }) : null;
    }, [pathname]);

    const isActivePath = useCallback(
        (path: string) => {
            return (
                (path === '/' && (pathname === '/' || pathname === '/assets' || pathname?.includes('/assets?'))) ||
                (path !== '/' && pathname?.includes(path))
            );
        },
        [pathname],
    );

    const getLinkHref = useCallback((item: any) => {
        const destinationPathParts = {
            prefix: '/assets',
            middle: item.href === '/' ? '' : item.href,
            suffix: '',
        };

        return `${destinationPathParts.prefix}${destinationPathParts.middle}${destinationPathParts.suffix}`;
    }, []);

    const renderAccountButton = useMemo(() => {
        if (!onLogOut) {
            return null;
        }

        if (!accountAddress) {
            return (
                <div className="flex items-center justify-center md:order-2">
                    <button className="button button--main h-7 sm:h-8 lg:h-9 cursor-pointer" onClick={connect}>
                        <span className="button__inner font-metro-bold h-7 sm:h-8 lg:h-9">
                            {loading ? <Spinner /> : !isVaultLayout ? actionButtonLabel : 'Login'}
                        </span>
                    </button>
                </div>
            );
        }

        return (
            <RenderAccountWithVault
                isVaultLayout={isVaultLayout}
                accountAddress={accountAddress}
                setOpenConnect={setOpenConnect}
                onLogOut={onLogOut}
            />
        );
    }, [accountAddress, connect, loading, setOpenConnect, onLogOut, isVaultLayout, actionButtonLabel]);

    const renderOptions = useMemo(() => {
        if (hideOptionsMenu) {
            return <></>;
        }

        const dropdownHasActiveItem = moreNavigation.some((option: NavItem) => isActivePath(option.href));

        return (
            <>
                <nav ref={navigation} className="flex">
                    {(isMobile ? options?.navigations : priorityNavigation)?.map((item: NavItem, index: number) => (
                        <Link
                            key={`header-${index}`}
                            href={getLinkHref(item)}
                            className={classNames(
                                'flex items-center min-w-fit cursor-pointer w-max rounded-md px-1 font-metro-bold relative text-base max-xl:mr-1.5 xl:ml-1',
                                isActivePath(item.href)
                                    ? 'bg-primary-cta-color-60 text-primary-cta-layer-color-60 hover:bg-primary-cta-color-60 md:hover:bg-primary-cta-color-40 max-xl:h-6'
                                    : 'text-neutral-control-layer-color-60 hover:bg-neutral-control-color-30',
                            )}>
                            {item.name}
                        </Link>
                    ))}
                </nav>

                {!mobileDevice && (
                    <Combobox>
                        <div ref={moreMenu} className="ml-1 w-[64px] relative">
                            {moreNavigation.length > 0 && (
                                <>
                                    <Combobox.Button
                                        className={classNames(
                                            'flex items-center justify-center cursor-pointer rounded-md px-1 font-metro-bold text-base',
                                            dropdownHasActiveItem
                                                ? 'bg-primary-cta-color-60 text-primary-cta-layer-color-60 hover:bg-primary-cta-color-60'
                                                : 'bg-neutral-control-color-30 text-neutral-control-layer-color-60 hover:bg-neutral-control-color-40',
                                        )}>
                                        <span className="inline-block">More</span>
                                        <ChevronDownIcon width={20} />
                                    </Combobox.Button>
                                    <Transition>
                                        <Combobox.Options className="absolute right-0 z-50 no-scrollbar mt-2 bg-dropdown-background-color shadow-sm border border-divider-color-20 max-h-96 rounded text-sm ring-1 ring-black ring-opacity-5 overflow-auto">
                                            {moreNavigation.map((category: any) => (
                                                <Link
                                                    href={getLinkHref(category)}
                                                    key={category.href}
                                                    className={classNames(
                                                        'm-[6px] px-4 py-1 rounded-sm block hover:text-neutral-control-layer-color-100 font-metro-regular font-normal whitespace-nowrap',
                                                        {
                                                            'bg-primary-cta-color-10 text-neutral-control-layer-color-100':
                                                                isActivePath(category.href),
                                                            'text-neutral-control-layer-color-70 hover:bg-neutral-control-color-20':
                                                                !isActivePath(category.href),
                                                        },
                                                    )}>
                                                    {category.name}
                                                </Link>
                                            ))}
                                        </Combobox.Options>
                                    </Transition>
                                </>
                            )}
                        </div>
                    </Combobox>
                )}
            </>
        );
    }, [
        mobileDevice,
        hideOptionsMenu,
        moreNavigation,
        priorityNavigation,
        navigation?.current,
        getLinkHref,
        isActivePath,
        moreMenu?.current,
    ]);

    return (
        <header
            className={classNames(
                {
                    'flex items-center justify-between md:w-[calc(100%-80px)] w-full border-b border-divider-color-20 px-4 md:px-6 sticky top-0 md:py-[0.34rem] md:cr-header z-20 bg-body-background-color h-[60px] gap-3':
                        !mobileDevice,
                },
                'md:ml-[80px] relative',
            )}>
            <div
                className={classNames(
                    'leading-8 flex max-xl:justify-between xl:flex-grow items-center md:px-6 max-xl:pt-4 xl:px-0 lg:pb-0',
                    isVaultLayout ? 'max-md:px-4' : 'max-md:px-[18px]',
                )}>
                {((mobileDevice && !accountAddress) || !mobileDevice) && (
                    <Link href="/" className="h-11 w-32" aria-label="Home page" onClick={logoClickHandler}>
                        <div className="cursor-pointer block">
                            {headerLogoIcon || (
                                <YellowLogo classNames="w-[106px] focus:outline-none focus:ring-none text-neutral-control-layer-color-100" />
                            )}
                        </div>
                    </Link>
                )}
                {mobileDevice && renderAccountButton}
            </div>
            {options && (
                <div
                    ref={navigationContainer}
                    className="relative flex xl:flex-grow items-center w-full sm:pl-4 xl:pl-0 max-xl:leading-8 pl-[18px] pr-[10px] md:pl-[24px] max-xl:mt-6 max-xl:overflow-scroll no-scrollbar lg:pb-0">
                    {options.customHeaderContent ? (
                        <div className="flex items-center">{options.customHeaderContent}</div>
                    ) : (
                        renderOptions
                    )}
                </div>
            )}
            {!mobileDevice && (
                <div className="flex items-center justify-center md:order-2 max-xl:hidden w-fit min-w-fit">
                    {renderAccountButton}
                </div>
            )}
        </header>
    );
};

export default Header;
